<template>
  <div>
    <Breadcrumbs
      page_title="lessonlearn"
      :items="breadcrumbs"
      :item_no="item_no"
      :item_btn_add="true"
      :btn_text="'addnew'"
      @ShowAction="ShowAction"
    />

    <v-card class="mx-3 my-5" style="border-radius: 16px">
      <v-container>
        <v-row justify="space-between" class="ma-1">
          <v-col cols="12" xs="12" sm="3" lg="3" md="3">
            <v-text-field
              outlined
              dense
              clearable
              style="border-radius: 8px"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-0">
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              :search="search"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="exprt-table"
              hide-default-footer
            >
              <template v-slot:item.sort="{ item }">
                <div class="d-flex">
                  <v-menu open-on-hover offset-y min-width="55px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="55"
                        height="30"
                        color="#47484b"
                        class="text-capitalize mt-1 sort_Btn"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.sort }}
                        <v-icon style="margin-left: 0px; margin-right: -0.6rem"
                          >mdi-chevron-down</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list
                      :style="
                        items.length == 1
                          ? { height: '69px' }
                          : items.length == 2
                          ? { height: '103px' }
                          : items.length == 3
                          ? { height: '136px' }
                          : items.length == 4
                          ? { height: '170px' }
                          : { height: '205px' }
                      "
                      class="overflow-y-auto"
                    >
                      <v-list-item
                        v-for="(temp, index) in sortNumberItems"
                        :key="index"
                        @click="
                          (item.sort = temp.value), changeSubmitSortNo(item)
                        "
                      >
                        <v-list-item-title>{{ temp.value }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-btn
                    v-if="$vuetify.breakpoint.smAndUp"
                    color="#4FB14E"
                    text
                    style="font-size: 15px"
                    class="text-capitalize"
                    @click="checkLessonLearnEdit(item)"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon
                    >{{ $t("edit") }}</v-btn
                  >
                  <v-btn
                    v-if="$vuetify.breakpoint.smAndUp"
                    color="#FF6060"
                    style="font-size: 15px"
                    text
                    class="text-capitalize"
                    @click="checkLessonLearnDelete(item)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>{{ $t("delete") }}</v-btn
                  >
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3">
          <v-col cols="12" xs="12" sm="9">
            <div class="d-flex text-left">
              <v-pagination
              circle
              v-model="page"
              class="pagination"
              :length="pageCount"
            ></v-pagination>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="3"            
            class="d-flex justify-end"
          >
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1 mr-1"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <Edit
      :show="editDialog"
      :editData="editData"
      :fullName="fullName_"
      @close="editDialog = false"
      @confirm="(editDialog = false), getData()"
    />

    <!-- Delete -->
    <v-dialog v-model="dialog" width="587">
      <v-card class="pa-4">
        <v-card-title style="font-size: 25px" class="justify-start">{{
          $t("deleteconfirm")
        }}</v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="
              font-size: 20px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >
            {{ $t("suredelete") }}
          </p>
          <p
            style="
              font-size: 20px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >
            "Knowledge Sharing {{ editData.caseTitle }}" ?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="
              font-size: 16px;
              border-radius: 8px;
              border: 1px solid #c0c0c0;
            "
            width="100"
            text
            outlined
            @click="dialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize white--text"
            width="100"
            style="font-size: 16px; border-radius: 8px"
            color="#FF6060"
            @click="deleteData()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success -->
    <v-dialog v-model="successDialog" width="587">
      <v-card>
        <v-card-title
          style="font-size: 30px"
          class="secondary white--text justify-center"
          >{{ message }}</v-card-title
        >

        <v-card-text>
          <p
            style="
              font-size: 16px;
              color: #4d4f5c;
              opacity: 1;
              text-align: center;
            "
            class="mt-3"
          >
            Deleting Lesson Learn is successful.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            class="text-capitalize mx-auto"
            width="100"
            color="#6CC8A0"
            @click="successDialog = false"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Add
      :show="addDialog"
      @close="addDialog = false"
      :fullName="fullName_"
      @confirm="
        (addDialog = false),
          (successDialog1 = true),
          (message = $t('addlessonlearn'))
      "
    />
    <SuccessDialog
      :show="successDialog1"
      :title="$t('success')"
      :text="message"
      @close="(successDialog1 = false), getData()"
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";
export default {
  components: {
    Add,
    Edit,
  },
  data: () => ({
    fullName_: "",
    sortNumberItems: [], //"", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    permissionDialog: false,
    permissionMessage: "",
    breadcrumbs: [
      {
        // image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "lessonlearn",
      },
    ],
    items: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    editData: {},
    dialog: false,
    successDialog: false,
    successDialog1: false,
    message: "",
    search: null,
    addDialog: false,
    editDialog: false,
    loading: true,
    item_no: 0,
  }),
  computed: {
    ...mapGetters([
      "editLessonLearnData",
      "showFirstTimeEditDataForLessonLearn",
    ]),
    headers() {
      return [
        {
          text: "#",
          value: "no",
          align: "left",
          class: "tableColor",
          sortable: false,
          width: "20px",
        },
        {
          text: this.$t("title"),
          align: "left",
          value: "caseTitle",
          class: "tableColor",
          width: "250px",
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "caseDescription",
          class: "tableColor",
          width: "250px",
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startContentDate1",
          width: "160px",
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endContentDate1",
          width: "160px",
        },
        {
          text: this.$t("createdateM"),
          align: "left",
          value: "createDate",
          width: "170px",
        },
        // {
        //   text: this.$t("createdby"),
        //   align: "left",
        //   value: "createBy",
        //   width: "160px"
        // },
        {
          text: this.$t("sort"),
          align: "left",
          value: "sort",
          width: "110px",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "actions",
          class: "tableColor",
          width: "220px",
        },
      ];
    },
  },
  mounted() {
    this.getData();

    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.fullName_ = auth.fullName;
    }
  },
  methods: {
    async changeSubmitSortNo(v) {
      const data = {
        id: v.id,
        sort: v.sort == "" ? null : v.sort,
        separatePage: "Lesson Learn",
      };
      await this.$axios.post(`${this.web_url}Contents/UpdateSort`, data);
      this.getData();
    },
    async checkLessonLearnDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("lessonlearn");
      if (checkPermission) {
        self.dialog = true;
        self.editData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to delete Lesson Learn.";
      }
    },
    async checkLessonLearnEdit(item) {
      let self = this;
      let checkPermission = self.check_Permissions("lessonlearn");
      if (checkPermission) {
        self.editDialog = true;
        self.editData = item;
        self.editLessonlearn(item);
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to edit Lesson Learn.";
      }
    },
    async checkLessonLearnAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("lessonlearn");
      if (checkPermission) {
        self.addDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to add Lesson Learn.";
      }
    },
    editLessonlearn(item) {
      let groupSelected = [];
      let userSelected = [];
      if (item.isUserGroup == 1) {
        for (let i = 0; i < item.permissionData.length; i++) {
          groupSelected.push({
            groupID: item.permissionData[i].userPermissionID,
            // userPermissionID: item.permissionData[i].userPermissionID,
            groupName: item.permissionData[i].fullName,
            isComplete: item.permissionData[i].isComplete,
            isUser: item.permissionData[i].isUser,
            perDay:
              item.permissionData[i].perDay == null
                ? null
                : item.permissionData[i].perDay.split("T")[0],
            //permission: item.permissionData[i].permission,
            request: item.permissionData[i].request,
            times: item.permissionData[i].times,
            selectedDate: "",
            index: i,
          });
        }
      } else {
        for (let j = 0; j < item.permissionData.length; j++) {
          userSelected.push({
            //employeeID: item.permissionData[j].employeeID,
            userID: item.permissionData[j].userPermissionID,
            fullName: item.permissionData[j].fullName,
            isComplete: item.permissionData[j].isComplete,
            isUser: item.permissionData[j].isUser,
            perDay:
              item.permissionData[j].perDay == null
                ? null
                : item.permissionData[j].perDay.split("T")[0],
            // permission: item.permissionData[j].permission,
            request: item.permissionData[j].request,
            times: item.permissionData[j].times,
            selectedDate: "",
            index: j,
          });
        }
      }
      this.$store.commit("saveEditLessonLearn", {
        ...item,
        userSelected,
        groupSelected,
      });
      this.$store.commit("showFirstTimeEditDataForLessonLearn");
      this.editDialog = true;
    },
    async getData() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.get(
          `${self.web_url}CaseStudies/GetCaseStudies?Id=` +
            localStorage.getItem("companyID")
        );
        console.log("emaildata",res.data.data);
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          createDate: moment(res.data.data[i].createDate)
            .local()
            .format("DD/MM/YYYY"),
          updateDate: moment(res.data.data[i].updateDate)
            .local()
            .format("DD/MM/YYYY"),
          startContentDate1: moment(res.data.data[i].startContentDate)
            .local()
            .format("DD/MM/YYYY"),
          endContentDate1: moment(res.data.data[i].endContentDate)
            .local()
            .format("DD/MM/YYYY"),
        }));
        self.item_no = self.items.length;
        let temp_null = [{ value: "" }];
        let temp_num = self.items.map((v, i) => {
          return { value: i + 1 };
        });
        self.sortNumberItems = temp_null.concat(temp_num);
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Lesson Learn.";
      }
      self.loading = false;
    },

    async deleteData() {
      const res = await this.$axios.post(
        `${this.web_url}CaseStudies/RemoveCaseStudy`,
        { id: this.editData.id }
      );
      console.log(res);
      this.message = this.$t("deletelessonlearn");
      this.successDialog1 = true;
      this.dialog = false;
      this.getData();
    },

    ShowAction(v) {
      if (v == "Add") {
        this.checkLessonLearnAdd();
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-text-field__details {
  display: none !important;
}
::v-deep .exprt-table {
  margin-left: 16px;
  margin-right: 16px;
}
::v-deep .exprt-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .exprt-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
  height: 65px;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}

/* For Sort menu. */
::v-deep .sort_Btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 50px;
  font-size: 16px;
  border: 1px solid #c0c0c0;
  opacity: 1 !important;
  padding: 0 0;
  border-radius: 8px;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-track {
  width: 6px !important;
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
/* Handle */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 2px #e6e6e6;
  border-radius: 7px;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
::v-deep .v-list-item {
  min-height: 32px;
  border-top: 2px solid #707070;
}
::v-deep .v-list-item:hover {
  background-color: #707070 !important;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #ffffff !important;
}
/* end sort menu. */
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
    margin-top: 1.5rem !important;
  }
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
</style>

<style>
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
</style>
